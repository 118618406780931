export class TrackPageEvent {
  path: string;
  title: string;

  constructor(path: string, title: string) {
    this.path = path;
    this.title = title;
  }
}

export class TrackClickEvent {
  action: string;
  category: string;
  label: string;
  value: string;
  isSale: string;
  likeOrigin: string;
  imageType: string;

  constructor(values: any) {
    this.action = values.action;
    this.category = values.category;
    this.label = values.label;
    this.value = values.value;
    this.isSale = values.isSale;
    this.likeOrigin = values.likeOrigin;
    this.imageType = values.imageType;
  }
}

export interface IConfig {
  analyticsTrackingId : string;
}

export type TrackingEvent = TrackPageEvent | TrackClickEvent;
