import {IConfig, TrackClickEvent, TrackingEvent, TrackPageEvent} from "./models";
import {CookieDescription} from "../../components/cookie-banner/models";

export const TRACKING_SERVICE_KEY = 'NY_TRACKER';
export const COOKIE_KEY = 'NY_COOKIE_MARKETING';

declare global {
  interface Window {
    NY_TRACKER: ITrackingService
    dataLayer: any[]
    NY_CONFIG:  IConfig
  }
}

export interface ITrackingService {
  trackClick(event: TrackClickEvent): void;
  trackPage(path: string, title: string): void;
}

export class TrackingService implements ITrackingService {
  private initialized: boolean = false;
  private queue: TrackingEvent[] = [];
  private readonly GA_TRACKING_ID: string = "";

  constructor(trackingID: string) {
    !trackingID ? console.error("No tracking ID provided") : '';
    this.GA_TRACKING_ID = trackingID;
    this.onCookiesUpdated = this.onCookiesUpdated.bind(this)
    this._gtag = this._gtag.bind(this)
    this.activateTracking = this.activateTracking.bind(this)
    this.deactivateTracking = this.deactivateTracking.bind(this)
    this.trackClick = this.trackClick.bind(this)
    this.trackPage = this.trackPage.bind(this)

    window.dataLayer = window.dataLayer || [];

    this._gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    })

    this.initializeTracking()
    window.addEventListener('ny-cookies-updated', this.onCookiesUpdated)
  }


  onCookiesUpdated(event: Event) {
    const cookieDescriptions = (event as CustomEvent<CookieDescription[]>).detail;
    const marketingCookie = cookieDescriptions.find(cd => cd.key === COOKIE_KEY);
    if (marketingCookie?.userPermission) {
      this.activateTracking();
    } else {
      this.deactivateTracking();
    }
  }

  private initializeTracking() {
    const trackingPermission = localStorage.getItem(COOKIE_KEY);

    if (trackingPermission === 'true') {
      this.activateTracking();
    } else {
      this.deactivateTracking();
    }
  }

  // @ts-ignore
  private _gtag(...args: any[]) {
    window.dataLayer.push(arguments)
  }

  private activateTracking() {
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + this.GA_TRACKING_ID;
    document.body.appendChild(script);

    this._gtag('js', new Date());

    this._gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'granted'
    })

    this._gtag('config', this.GA_TRACKING_ID, {
      'anonymize_ip': true
    });

    this.initialized = true;
    (<any>window)[`ga-disable- + ${this.GA_TRACKING_ID}`] = undefined;

    for (const event of this.queue) {
      if (event instanceof TrackPageEvent) {
        this.trackPage(event.path, event.title);
      } else {
        this.trackClick(event);
      }
    }
  }

  private deactivateTracking() {
    this.initialized = false;
    (<any>window)['ga-disable-' + this.GA_TRACKING_ID] = true;

    this._gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    })
  }

  trackClick(event: TrackClickEvent) {
    if (!this.initialized) {
      this.queue.push(new TrackClickEvent(event));
      return
    }

    this._gtag('config', this.GA_TRACKING_ID, {
      'send_page_view': false,
      'custom_map': {
        'dimension1': 'is_product_on_sale',
        'dimension2': 'like_origin',
        'dimension3': 'image_type'
      }
    });

    this._gtag('event', event.action, {
      'event_category': event.category,
      'event_label': event.label,
      'value': event.value,
      'is_product_on_sale': event.isSale,
      'like_origin': event.likeOrigin,
      'image_type': event.imageType
    });
  }

  trackPage(path: string, title: string) {
    if (!this.initialized) {
      this.queue.push(new TrackPageEvent(path, title));
      return
    }

    this._gtag('event', 'page_view', {
      'page_title': title,
      'page_path': path
    })
  }
}
export const trackingService = new TrackingService(window.NY_CONFIG?.analyticsTrackingId);