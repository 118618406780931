import {html, LitElement} from 'lit'
import {customElement, property, state} from 'lit/decorators.js'
import {i18nService} from '../../services/i18nService';
import {cookieService} from "../../services/cookieService";
import style from "./cookie-overlay.scss?inline";
import {CookieDescription, DISABLE_OPT_IN_PARAM} from "./models";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {getUrlParam} from "./utils";
import {trackingService} from "../../services/tracking/tracking-service";
import {TrackClickEvent} from "../../services/tracking/models";

declare global {
  interface HTMLElementTagNameMap {
    'cookie-overlay': CookieOverlay
  }
}

@customElement('cookie-overlay')
export class CookieOverlay extends LitElement {

  @property() cookie: string = '';
  @property() placeholder: string = '';
  @state() i18nInitialized: boolean = false;
  @state() cookieDescriptionInitialized: boolean = false;
  @state() cookieDescription: CookieDescription = new CookieDescription({});
  @state() hasPermission: boolean = false;

  private static readonly CookieSettingsOpenedTrackingEvent = new TrackClickEvent({
    category: "NY-Cookies",
    action: "cookie-settings opened",
    label: "cookie-overlay"
  })

  constructor() {
    super();
    this.onCookiesUpdated = this.onCookiesUpdated.bind(this);
    this.acceptCookie = this.acceptCookie.bind(this);
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  async connectedCallback() {
    super.connectedCallback();
    await i18nService.initTranslations();
    this.i18nInitialized = true;
    cookieService.subscribeForCookies(() => {
      this.hasPermission = cookieService.checkPermission(this.cookie);
      const cookieDescription = cookieService.getCookieDescription(this.cookie);
      if (cookieDescription) {
        this.cookieDescription = cookieDescription;
        this.cookieDescriptionInitialized = true;
      }
    })
    window.addEventListener('ny-cookies-updated', this.onCookiesUpdated)
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('ny-cookies-updated', this.onCookiesUpdated)
  }

  onCookiesUpdated(event: Event) {
    const cookieDescriptions = (event as CustomEvent<CookieDescription[]>).detail;
    for (const cd of cookieDescriptions) {
      if (cd.key === this.cookie) {
        this.hasPermission = cd.userPermission;
      }
    }
  }

  acceptCookie() {
    if (this.cookieDescription) {
      const cookieAcceptedTrackingEvent = new TrackClickEvent({
        category: "NY-Cookies",
        action: "accept one",
        label: this.cookieDescription.key
      })
      trackingService.trackClick(cookieAcceptedTrackingEvent);
      cookieService.acceptCookie(this.cookieDescription.key);
    }
  }

  openCookieSettings() {
    trackingService.trackClick(CookieOverlay.CookieSettingsOpenedTrackingEvent);
    cookieService.openCookieSettings();
  }

  render() {
    if (!this.cookieDescriptionInitialized && !this.i18nInitialized) {
      return
    }

    if (this.hasPermission || getUrlParam(DISABLE_OPT_IN_PARAM)) {
      const template = this.querySelector('template');
      if (template) {
        return html`${document.importNode(template.content, true)}`
      }
    }

    return html`
      <style>${style}</style>
      <div class="overlay-background ${this.placeholder ? 'has-placeholder' : ''}">
        ${this.placeholder && html`<img src="${this.placeholder}" alt="placeholder"/>`}
        <div class="cookie-overlay">
          <h3>${i18nService.translate(this.cookieDescription.overlayHeadlineText)}</h3>

          ${this.cookieDescription.termsOfUseUrl ? html`<p>
            ${unsafeHTML(i18nService.translate(this.cookieDescription.overlayDescriptionText, this.cookieDescription.infoUrl, this.cookieDescription.termsOfUseUrl))}</p>` : html`
            <p>
              ${unsafeHTML(i18nService.translate(this.cookieDescription.overlayDescriptionText, i18nService.translate(this.cookieDescription.headlineText), this.cookieDescription.infoUrl))}</p>`}
          <div class="overlay-controls">
            <button @click="${this.acceptCookie}">${i18nService.translate(this.cookieDescription.overlayButtonText)}
            </button>
            <button @click="${this.openCookieSettings}">${i18nService.translate('cookie_overlay.button.settings')}
            </button>
          </div>
        </div>
      </div>
    `
  }
}