import {html, LitElement} from 'lit'
import {customElement, state} from 'lit/decorators.js'
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import {i18nService} from '../../services/i18nService';
import {cookieService} from "../../services/cookieService";
import style from "./cookie-banner.scss?inline";
import {addCountryToRelativeUrl, getCountryFromUrl, getUrlParam} from "./utils";
import {DISABLE_OPT_IN_PARAM} from "./models";
import closeButton from "../../assets/close_icon.svg";
import {TrackClickEvent} from "../../services/tracking/models";
import {trackingService} from "../../services/tracking/tracking-service";

declare global {
  interface HTMLElementTagNameMap {
    'cookie-banner': CookieBanner
  }
}

@customElement('cookie-banner')
export class CookieBanner extends LitElement {

  @state() i18nInitialized: boolean = false
  @state() active: boolean = true;

  private readonly privacyUrl = '/legal/privacy';
  private static readonly AcceptAllCookiesTrackingEvent = new TrackClickEvent({category: "NY-Cookies", action: "accept all", label: "cookie-banner"})
  private static readonly AcceptRequiredCookiesTrackingEvent = new TrackClickEvent({category: "NY-Cookies", action: "accept required", label: "cookie-banner"})
  private static readonly CookieSettingsOpenedTrackingEvent = new TrackClickEvent({category: "NY-Cookies", action: "cookie-settings opened", label: "cookie-banner"})

  constructor() {
    super();
    this.close = this.close.bind(this);
  }

  handleButtonAcceptAll() {
    trackingService.trackClick(CookieBanner.AcceptAllCookiesTrackingEvent);
    cookieService.acceptAllCookies()
  }

  handleButtonAcceptRequired() {
    trackingService.trackClick(CookieBanner.AcceptRequiredCookiesTrackingEvent);
    cookieService.acceptRequiredCookies()
  }

  handleButtonSettings() {
    trackingService.trackClick(CookieBanner.CookieSettingsOpenedTrackingEvent);
    cookieService.openCookieSettings();
  }

  close() {
    this.active = false;
  }

  handleShouldShow() {
    if (getUrlParam(DISABLE_OPT_IN_PARAM)) {
      this.active = false;
    }

    const lastSeen = cookieService.getLastSeen();
    if (lastSeen) {
      this.active = false;
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    await i18nService.initTranslations();
    this.i18nInitialized = true;
    window.addEventListener('ny-cookies-updated', this.close);
    this.handleShouldShow();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('ny-cookies-updated', this.close);
  }

  renderInfoText() {
    const infoTranslation = i18nService.translate("cookie_banner.info.text", addCountryToRelativeUrl(this.privacyUrl), "[BUTTON]");
    const textParts = infoTranslation.split('[BUTTON]');
    return html`${unsafeHTML(textParts[0])}<button @click="${this.handleButtonSettings}">${i18nService.translate('cookie_banner.button_settings.text')}</button>${unsafeHTML(textParts[1])}`
  }

  showCloseButton() {
    return getCountryFromUrl() === 'it';
  }

  render() {
    if (!this.i18nInitialized || !this.active) return;
    return html`
      <style>${style}</style>
      <div class="cookie-banner">
        ${this.showCloseButton() ? html`
        <div class="close" @click="${this.handleButtonAcceptRequired}">
          <img src="${closeButton}" alt="close">
        </div>` : ''}
        <div class="cookie-banner-content">
          <p>${this.renderInfoText()}</p>
        </div>
        <div class="cookie-banner-controls">
          <button @click="${this.handleButtonAcceptAll}">
            ${i18nService.translate("cookie_banner.button_accept_all.text")}
          </button>
          <button @click="${this.handleButtonAcceptRequired}">
            ${i18nService.translate("cookie_banner.button_accept_required.text")}
          </button>
          <button @click="${this.handleButtonSettings}">
            ${i18nService.translate("cookie_banner.button_settings.text")}
          </button>
        </div>
      </div>
    `
  }
}