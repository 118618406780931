import {CookieDescription} from "../components/cookie-banner/models";
import {conf} from "../config";

class CookieService {

    private cookieDescriptions: CookieDescription[] = [];
    private COOKIE_BANNER_LAST_SEEN_KEY = 'NY_COOKIE_BANNER_LAST_SEEN';

    private cookieSubscribers: any = [];

    openCookieSettings() {
        window.dispatchEvent(new CustomEvent('ny-menu-closed', {detail: this as CookieService}));
        window.dispatchEvent(new CustomEvent('ny-cookie-settings-open'));
    }

    subscribeForCookies(callback: (cookieData:  CookieDescription[])=>void) {
        this.cookieSubscribers.push(callback);

        if (this.cookieDescriptions.length > 0) {
            callback(this.cookieDescriptions)
        }
    }

    notifyCookieSubscribers(cookieData: CookieDescription[]){
        this.cookieDescriptions = cookieData;
        this.cookieSubscribers.forEach((callback: any)=> callback(cookieData))
    }

    private dispatchCookiesUpdated(cd: CookieDescription[]) {
        window.dispatchEvent(new CustomEvent<CookieDescription[]>('ny-cookies-updated', {detail: cd}));
    }

    getLastSeen() {
        const dateString = localStorage.getItem(this.COOKIE_BANNER_LAST_SEEN_KEY);
        if (!dateString) {return null}
        return new Date(dateString);
    }

    setLastSeen(date: Date) {
        localStorage.setItem(this.COOKIE_BANNER_LAST_SEEN_KEY, date.toISOString());
    }

    checkPermission(key: string) {
        return this.getCookieValueForKey(key);
    }

    acceptAllCookies() {
        for (const cd of this.cookieDescriptions) {
            this.setCookieValueForKey(cd.key, true)
        }
        this.updateCookiesWithLocalStoreValues();
        this.setLastSeen(new Date());
        this.dispatchCookiesUpdated(this.cookieDescriptions);
    }

    acceptRequiredCookies() {
        for (const cd of this.cookieDescriptions) {
            this.setCookieValueForKey(cd.key, cd.isRequired)
        }
        this.updateCookiesWithLocalStoreValues()
        this.setLastSeen(new Date());
        this.dispatchCookiesUpdated(this.cookieDescriptions);
    }

    updateCookies(cookieDescriptions: CookieDescription[]) {
        for (const cd of cookieDescriptions) {
            this.setCookieValueForKey(cd.key, cd.userPermission)
        }
        this.updateCookiesWithLocalStoreValues();
        this.dispatchCookiesUpdated(this.cookieDescriptions);
    }

    private getCookieValueForKey(key: string) {
        return (localStorage.getItem(key) === "true")
    }

    private setCookieValueForKey(key: string, value: boolean) {
        localStorage.setItem(key, value ? "true" : "false")
    }

    getCookieDescription(key: string) {
        return this.cookieDescriptions.find(it => it.key === key);
    }

    acceptCookie(key: string) {
        for (const cookieDescription of this.cookieDescriptions) {
            if (cookieDescription.key === key) {
                cookieDescription.userPermission = true;
            }
        }
        this.updateCookies(this.cookieDescriptions);
    }

    async loadCookieDescriptions(contextValue: string) {
        try {
            const res = await fetch(`${conf.assetServiceUrl}/cookies/?context=${contextValue}`);
            if (res.ok) {
                this.cookieDescriptions = await res.json();
                this.updateCookiesWithLocalStoreValues();
            }
        } catch (e) {
            console.error('error loading cookies: ', e);
        }
        return this.cookieDescriptions;
    }

    private updateCookiesWithLocalStoreValues() {
        this.cookieDescriptions = this.cookieDescriptions.map(cd => {
            cd.userPermission = !cd.isRequired ? this.getCookieValueForKey(cd.key) : true;
            return cd
        });
    }
}

export const cookieService = new CookieService();
