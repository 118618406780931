export const DISABLE_OPT_IN_PARAM = 'disableOptIn'

export class CookieDescription {
    public key: string;
    public isRequired: boolean;
    public headlineText: string;
    public descriptionText: string;
    public overlayHeadlineText: string;
    public overlayDescriptionText: string;
    public overlayButtonText: string;
    public infoUrl: string;
    public termsOfUseUrl: string;
    public userPermission: boolean;

    constructor(values: any) {
        this.key = values.key;
        this.isRequired = values.isRequired;
        this.headlineText = values.headlineText;
        this.descriptionText = values.descriptionText;
        this.overlayHeadlineText = values.overlayHeadlineText;
        this.overlayDescriptionText = values.overlayDescriptionText;
        this.overlayButtonText = values.overlayButtonText;
        this.infoUrl = values.infoUrl;
        this.termsOfUseUrl = values.termsOfUseUrl;
        this.userPermission = values.userPermission;
    }
}
