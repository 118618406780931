import {html, LitElement} from 'lit'
import {customElement, state, property} from 'lit/decorators.js'
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import {repeat} from 'lit/directives/repeat.js';
import {i18nService} from '../../services/i18nService';
import {cookieService} from "../../services/cookieService";
import style from "./cookie-settings.scss?inline";
import {CookieDescription, DISABLE_OPT_IN_PARAM} from "./models";
import closeButton from "../../assets/close_icon.svg";
import {addCountryToRelativeUrl, getUrlParam} from "./utils";

declare global {
  interface HTMLElementTagNameMap {
    'cookie-settings': CookieSettings
  }
}

@customElement('cookie-settings')
export class CookieSettings extends LitElement {

  @property() context: string = '';
  @state() i18nInitialized: boolean = false
  @state() cookieDescriptions: CookieDescription[] = [];

  constructor() {
    super();
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();
    await i18nService.initTranslations();
    this.cookieDescriptions = await cookieService.loadCookieDescriptions(this.context);
    cookieService.notifyCookieSubscribers(this.cookieDescriptions)
    this.i18nInitialized = true;
    window.addEventListener('ny-cookie-settings-open', this.open)
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('ny-cookie-settings-open', this.open)
  }

  async open() {
    if (getUrlParam(DISABLE_OPT_IN_PARAM)) {
      return
    }

    this.cookieDescriptions = await cookieService.loadCookieDescriptions(this.context);
    this.setAttribute('active', '');
    document.documentElement.style.overflowY = 'hidden';
  }

  close() {
    this.removeAttribute('active');
    document.documentElement.removeAttribute('style');
  }

  acceptAllCookies() {
    cookieService.acceptAllCookies();
    this.close();
  }

  togglePermission(cd: CookieDescription) {
    cd.userPermission = !cd.userPermission;
  }

  updateCookies() {
    cookieService.updateCookies(this.cookieDescriptions);
    cookieService.setLastSeen(new Date());
    this.close();
  }

  render() {
    if (!this.i18nInitialized) return;
    return html`
      <style>${style}</style>
      <div class="closable-overlay" @click="${this.close}"></div>
      <div class="cookie-settings-container">
        <div class="cookie-settings-header">
          <h1>${i18nService.translate("cookie_settings.header.text")}</h1>
          <div class="cookie-settings-header-icon" @click="${this.close}">
            <img src="${closeButton}" alt="close">
          </div>
        </div>
        <div class="cookie-settings-body">
          <div class="cookie-settings-introduction">
            <p>${i18nService.translate("cookie_settings.introduction.text")}</p>
          </div>
          <div class="button-accept-all">
            <button @click="${this.acceptAllCookies}">${i18nService.translate("cookie_settings.introduction.button.text")}</button>
          </div>
          <div class="cookie-settings-selection">
            ${repeat(this.cookieDescriptions, (cd) => cd.key, (cd) => html`
              <div class="cookie-container">
                <div class="cookie-checkbox">
                <input type="checkbox" id="${cd.key}" name="${cd.key}" .checked=${cd.userPermission} .disabled="${cd.isRequired}"
                       @click="${() => this.togglePermission(cd)}">
                <label class="cookie-headline" for="${cd.key}">${i18nService.translate(cd.headlineText)}</label>
              </div>
              <p class="cookie-description">${unsafeHTML(i18nService.translate(cd.descriptionText, addCountryToRelativeUrl(cd.infoUrl)))}</p>
              </div>
            `)}
          </div>
        </div>
        <div class="cookie-settings-footer">
          <div class="button-save-selection">
            <button @click="${this.updateCookies}">${i18nService.translate("cookie_settings.footer.button.text")}</button>
          </div>
        </div>
      </div>
    `
  }
}